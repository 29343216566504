$(document).ready(function(){

    if($(window).width() >= 768)
        $('ul.nav li.dropdown').hover(function() {
            $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(250);
        }, function() {
            $(this).find('.dropdown-menu').stop(true, true).fadeOut(0);
        });

    $('.homepage-slideshow').cycle({
        slides: "> a",
        timeout: 5000,
        speed: 500,
    });

    $('.side-slideshow').cycle({
        slides: "> .item",
        timeout: 5000,
        speed: 500,
    });

    $(".loader").delay(500).fadeOut();
    $(".animationload").delay(1000).fadeOut(1500);


    $('#darekFilter #darekform select').on('change', function(){
        $('#darekform').submit();
    });

    if ($('#ajaxWhishesPlaces').length > 0) {
        loadAjaxWishes(0, 0, 0);
    }

    $('.wishAddModalTrigger').on('click', function(e){
        e.preventDefault();
        $("#chciPrispetModalLabel").html($(this).data('title'))
        $("#chciPrispetModal .modal-body img").attr('src', $(this).data('img'));
        $("#chciPrispetModal .wishAdd").data('id', $(this).data('id'));
        $("#chciPrispetModal").modal('show')
    });

    $('.wishAdd').on('click', function(e){
        e.preventDefault();
        if ($('#chciPrispetModal_price').val() < 100) {
            alert('Minimální výše příspěvku je 100 Kč');
        } else {
            loadAjaxWishes($(this).data('id'), 0, $('#chciPrispetModal_price').val());
            $("#chciPrispetModal").modal('hide')
            $('#wish' + $(this).data('id') + ' a.wishAddModalTrigger').fadeOut(1000);
            $('#chciPrispetModal_price').val(100);
            $(window).scrollTop(0);
        }
    });

    $('#ajaxWhishesPlaces').on('click', '.deleteWish', function(e){
        e.preventDefault();
        loadAjaxWishes(0, $(this).data('id'), 0);
    });

    $('#typPlatbyRozcestnik a').on('click', function(e){
        e.preventDefault();
        $('.hideAfterChoice').hide();
        $('#typPlatbyRozcestnik').hide();
        var type = $(this).data('type');
        $('#platbaForm .hiddenX').hide();
        $('#platbaForm .show_pay_' + type).show();
        $('#frm-typ').val(type);
    });

    $('#platbaForm form').on('submit', function(){
        if ($('#frm-typ').val() === "2") {
            if ($('#frm-jmeno').val() === '') { alert('Pole jméno je při platbě kartou povinné'); $('#frm-jmeno').focus(); return false; }
            if ($('#frm-telefon').val() === '') { alert('Pole telefon je při platbě kartou povinné'); $('#frm-telefon').focus(); return false; }
            if ($('#frm-prijmeni').val() === '') { alert('Pole příjmení je při platbě kartou povinné'); $('#frm-prijmeni').focus(); return false; }
            if ($('#frm-adresa').val() === '') { alert('Pole adresa je při platbě kartou povinné'); $('#frm-adresa').focus(); return false; }
            if ($('#frm-mesto').val() === '') { alert('Pole město je při platbě kartou povinné'); $('#frm-mesto').focus(); return false; }
            if ($('#frm-psc').val() === '') { alert('Pole PSČ je při platbě kartou povinné'); $('#frm-psc').focus(); return false; }
        }
    });

});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox({alwaysShowClose: true});
});

function loadAjaxWishes(addId, deleteId, castka) {
    $.get('/kouzelne-vanoce-wish-ajax.php', {'add': addId, 'delete' : deleteId, 'price': castka}, function(){
    }).done(function(data){
        if (data !== '') {
            $('#ajaxWhishesPlaces').parent().parent().show();
        } else {
            $('#ajaxWhishesPlaces').parent().parent().hide();
        }

        if (deleteId > 0) {
            if ($('#wish' + deleteId).length > 0) {
                $('#wish' + deleteId + ' a.wishAddModalTrigger').fadeIn(1000);
            } else {
                location.reload();
            }
        }

        $('#ajaxWhishesPlaces').html(data);
    });
}

function showCookieOption() {
    $('#option1').hide();
    $('#option2').show();
    return false;
}

function enableAllCookies() {
    $('#option2 input').prop('checked', true);
    $('#cookiesForm').submit();
    return false;
}

function disableCookies() {
    $('#cookiesForm').submit();
    return false;
}